import axios from "axios";

const instance = axios.create({
  //baseURL: "http://localhost:8000/api",
  //baseURL: "https://api.smkganesha.sch.id/public/api/",
  baseURL: "https://tesnet-api.smkganesha.sch.id/public/api/",
  
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
